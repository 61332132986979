import React from "react";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";

const serializers = {
  types: {
    youtube: ({ node }) => {
      const opts = {
        height: "100%",
        width: "100%",
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
      };

      const { url } = node;
      const id = getYouTubeId(url);
      return <YouTube opts={opts} videoId={id} className="aspect-video" />;
    },
    iframe: ({ node }) => {
      const { url, height } = node;
      return (
        <iframe title="iframe" src={url} width="100%" height={`${height}px`} />
      );
    },
  },
};

export default serializers;
